<template>
  <div class="row">
    <div class="col-md-12">
      <KTCodePreview v-bind:title="'Form Create Condition Monitoring'">
        <template v-slot:preview>
          <b-form class="form" @submit.stop.prevent="onSubmit">
            <b-row>
              <b-col md="6">
                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8"
                  id="equipment_id_label" label="Equipment :" label-for="equipment_id">
                  <vue-suggestion id="equipment_id" :items="equipment" v-model="ItemEquipment" :setLabel="setLabel"
                    :itemTemplate="itemTemplate" @changed="inputChangeEquipment" @selected="chooseEquipment"
                    inputClasses="form-control" placeholder="Enter Text..." size="sm">
                  </vue-suggestion>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8" id="pabrik_id_label"
                  label="Pabrik :" label-for="pabrik_id">
                  <b-form-select id="pabrik_id" v-model="form.pabrik_id" :options="pabrik"
                    @change="resetEquipment()"></b-form-select>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8" id="plant_id_label"
                  label="Plant :" label-for="plant_id">
                  <b-form-select id="plant_id" v-model="form.plant_id" :options="plant"
                    @change="resetEquipment()"></b-form-select>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8" id="part_label"
                  label="Part :" label-for="part_id">
                  <treeselect size="sm" id="part_id" v-model="form.part_id" :clearable="true" :async="true"
                    valueFormat="label" :load-options="loadOptionsPart" />
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8" id="sub_part_label"
                  label="Sub Part :" label-for="sub_part">
                  <vue-suggestion id="sub_part" :items="sub_part" v-model="itemSubPart" :setLabel="setLabel"
                    :itemTemplate="itemTemplate" @changed="inputChangeSubPart" @selected="itemSelectedSubPart"
                    inputClasses="form-control" placeholder="Enter Text...">
                  </vue-suggestion>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8" id="material_label"
                  label="Material :" label-for="material">
                  <b-form-input id="material" v-model="form.material" type="text"
                    placeholder="Enter Text..."></b-form-input>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="2" content-cols-sm content-cols-lg="8" id="deskripsi_label"
                  label="Deskripsi :" label-for="deskripsi">
                  <b-form-textarea id="deskripsi" v-model="form.deskripsi" placeholder="Enter Text..." rows="3"
                    max-rows="6"></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="8"
                  id="jenis_data_id_label" label="Jenis Data :" label-for="jenis_data_id">
                  <b-form-select id="jenis_data_id" v-model="form.jenis_data_id" :options="jenis_data"></b-form-select>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="8" id="satuan_label"
                  label="Satuan :" label-for="satuan">
                  <b-form-input id="satuan" v-model="form.satuan" type="text" placeholder="Enter Text..."></b-form-input>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="8"
                  id="nilai_awal_label" label="Nilai Awal :" label-for="nilai_awal">
                  <b-form-input id="nilai_awal" v-model="form.nilai_awal" type="number"
                    placeholder="Enter Text..."></b-form-input>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="8" id="batas_max_label"
                  label="Batas Max :" label-for="batas_max">
                  <b-form-input id="batas_max" v-model="form.batas_max" type="number"
                    placeholder="Enter Text..."></b-form-input>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="8" id="batas_min_label"
                  label="Batas Min :" label-for="batas_min">
                  <b-form-input id="batas_min" v-model="form.batas_min" type="number"
                    placeholder="Enter Text..."></b-form-input>
                </b-form-group>

                <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="8"
                  id="titik_ukur_label" label="Titik Ukur :" label-for="titik_ukur">
                  <b-form-radio-group v-model="form.titik_ukur" :options="['Text', 'MS. Excel']"
                    class="mb-3"></b-form-radio-group>
                </b-form-group>

                <b-row class="mb-3">
                  <b-col v-if="form.titik_ukur === 'MS. Excel'">
                    <small>Download template nama titik ukur
                      <a :href="base_url + 'media/template/template_nama_titik_ukur.xlsx'">dsni</a></small>
                    <b-form-file v-model="form.import" placeholder="import excel titik ukur..."
                      drop-placeholder="import excel titik ukur..." accept=".xlsx"></b-form-file>
                  </b-col>
                </b-row>
                <b-form-group v-if="form.titik_ukur === 'Text'" label-cols-sm="4" label-cols-lg="3" content-cols-sm
                  content-cols-lg="8" id="deskripsi_label" label="Pisahkan dengan koma :" label-for="deskripsi">
                  <b-form-textarea id="titik_ukur_koma" v-model="form.titik_ukur_koma"
                    placeholder="Titik 1, titik 2, titik 3" rows="3" max-rows="6"></b-form-textarea>
                </b-form-group>
                <b-form-group label-cols-sm="4" label-cols-lg="3" content-cols-sm content-cols-lg="8" id="sketsa_label"
                  label="Upload Sketsa :" label-for="sketsa">
                  <b-form-file id="sketsa" v-model="form.sketsa" placeholder="Choose a photo or drop it here..."
                    drop-placeholder="Drop file here..."></b-form-file>
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-button variant="outline-dark" :disabled="loading" @click="$router.push('/report/conditional_report')">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <span v-else class="svg-icon svg-icon-sm svg-icon-dark">
                    <i class="flaticon2-left-arrow-1"></i>
                  </span>
                  Discard
                </b-button>

                <b-button class="ml-3" type="submit" variant="outline-success" :disabled="loading">
                  <b-spinner small class="ml-auto" v-if="loading"></b-spinner>
                  <!-- <strong>Loading...</strong> -->
                  <span v-else class="svg-icon svg-icon-sm svg-icon-success">
                    <i class="flaticon2-check-mark"></i>
                  </span>
                  Submit
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </template>
      </KTCodePreview>
    </div>
  </div>
</template>

<style lang="scss">
@import '~vuetify/dist/vuetify.css';

.theme--light.v-application {
  background: transparent !important;
}

.v-application code {
  box-shadow: none !important;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue'
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { mapState } from 'vuex'

import ApiService from '@/core/services/api.service'
import $ from 'jquery'

import itemTemplate from '@/view/pages/item-template.vue'
import * as moment from 'moment'
import 'moment/locale/id'

import Treeselect from '@riophae/vue-treeselect'
import { ASYNC_SEARCH } from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

const simulateAsyncOperation = fn => {
  setTimeout(fn, 2000)
}

export default {
  name: 'addconditionmonitoring',
  data() {
    return {
      form: {
        pabrik_id: null,
        plant_id: null,
        equipment_id: null,
        part_id: null,
        sub_part: null,
        jenis_data_id: null,
        material: null,
        deskripsi: null,
        satuan: null,
        nilai_awal: null,
        batas_max: null,
        batas_min: null,
        titik_ukur: null,
        data_titik_ukur: [],
        import: null,
        sketsa: null,
        titik_ukur_koma: null
      },
      equipment: [],
      pabrik: [],
      plant: [],
      part: [],
      sub_part: [],
      jenis_data: [],

      ItemEquipment: null,
      ItemPart: null,
      itemSubPart: null,
      itemTemplate,

      show: true,
      loading: false,
      base_url: null,
    }
  },
  components: {
    KTCodePreview,
    Treeselect,
  },
  created() {
    this.base_url = process.env.BASE_URL

    var self = this

    ApiService.get('/master/pabrik/all')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var pabrik = data.data
          for (let i = 0; i < pabrik.length; i++) {
            self.pabrik.push({
              value: pabrik[i].id_pabrik,
              text: pabrik[i].nama_pabrik,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })

    ApiService.get('/report/condition_monitoring/jenis_data')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var jenis_data = data.data
          for (let i = 0; i < jenis_data.length; i++) {
            self.jenis_data.push({
              value: jenis_data[i].id_jenis_data,
              text: jenis_data[i].nama_jenis_data,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })

    ApiService.get('/master/plant/all?sortBy=nama_plant&sortDesc=ASC')
      .then(({ data }) => {
        if (data.status == 'ok') {
          var plant = data.data.data
          for (let i = 0; i < plant.length; i++) {
            self.plant.push({
              value: plant[i].id_plant,
              text: plant[i].nama_plant,
            })
          }
        }
      })
      .catch(response => {
        console.log(response)
      })
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Report', route: '/report/index' },
      { title: 'Create Condition Monitoring' },
    ])
  },
  methods: {
    moment: function (date) {
      moment.locale('id')
      return moment(date).format('DD/MM/YYYY')
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    addTitikUkur() {
      this.form.data_titik_ukur.push('')
    },
    removeTitikUkur(index) {
      this.form.data_titik_ukur.splice(index, 1)
    },
    resetEquipment() {
      this.ItemEquipment = {
        id: null,
        name: null,
      }

      this.form.equipment_id = null
    },
    loadOptionsPart({ action, searchQuery, callback }) {
      var self = this

      if (action === ASYNC_SEARCH) {
        simulateAsyncOperation(() => {
          ApiService.setHeader()

          ApiService.get('/master/part?page=1&nama_part=' + searchQuery)
            .then(({ data }) => {
              if (data.status == 'ok') {
                var part = data.data.data
                self.part.splice(0, self.part.length)

                for (let i = 0; i < part.length; i++) {
                  self.part.push({
                    id: part[i].id_part,
                    label: part[i].nama_part,
                  })
                }
              }
            })
            .catch(response => {
              console.log(response)
            })

          setTimeout(() => {
            callback(null, self.part)
          }, 500)
        })
      }
    },
    onSubmit() {
      // event.preventDefault()
      // this.$v.form.$touch();
      // if (this.$v.form.$anyError) {
      //     return;
      // }

      var self = this
      self.loading = true

      const pabrik_id = this.form.pabrik_id
      const plant_id = this.form.plant_id
      const equipment_id = this.form.equipment_id
      const part_id = this.form.part_id.id

      var sub_part = null
      if (typeof self.form.sub_part === 'object' && self.form.sub_part !== null) {
        sub_part = parseInt(self.form.sub_part.id)
      } else {
        sub_part = self.form.sub_part
      }

      const jenis_data_id = this.form.jenis_data_id
      const material = this.form.material
      const deskripsi = this.form.deskripsi
      const satuan = this.form.satuan
      const nilai_awal = this.form.nilai_awal
      const batas_max = this.form.batas_max
      const batas_min = this.form.batas_min
      const titik_ukur = this.form.titik_ukur
      // const data_titik_ukur = this.form.data_titik_ukur
      const data_titik_ukur = []
      const import_excel = this.form.import
      const sketsa = this.form.sketsa

      if (this.form.titik_ukur_koma != null) {
        const split_koma = this.form.titik_ukur_koma.split(",");
        if (split_koma.length > 0) {
          for (var i in split_koma) {
            data_titik_ukur.push(split_koma[i].trim());
          }
        }

      }

      const dataku = new FormData()
      dataku.append('pabrik_id', pabrik_id)
      dataku.append('plant_id', plant_id)
      dataku.append('equipment_id', equipment_id)
      dataku.append('part_id', part_id)
      dataku.append('sub_part', sub_part)
      dataku.append('jenis_data_id', jenis_data_id)
      dataku.append('material', material)
      dataku.append('deskripsi', deskripsi)
      dataku.append('satuan', satuan)
      dataku.append('nilai_awal', nilai_awal)
      dataku.append('batas_max', batas_max)
      dataku.append('batas_min', batas_min)
      dataku.append('titik_ukur', titik_ukur)
      dataku.append('import', import_excel)
      data_titik_ukur.forEach((row, index) => {
        dataku.append(`data_titik_ukur[${index}]`, row)
      })

      if (sketsa) {
        dataku.append('sketsa', sketsa)
      }

      var urlApi = ApiService.urlApi()

      $.ajax({
        url: urlApi + '/report/condition_monitoring/store',
        data: dataku,
        cache: false,
        contentType: false,
        processData: false,
        method: 'POST',
        headers: ApiService.headerAPi(),
        success: function (response) {
          if (response.status == 'ok') {
            self.makeToast('success', 'Data berhasil disimpan')
            self.$router.push('/report/conditional_report')
          }
        },
      })
    },
    setLabel(item) {
      return item.name
    },
    inputChangeEquipment(text) {
      // your search method
      var self = this
      var url = '/master/equipment/showequipment/' + text

      ApiService.get(url)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var equipment = data.data

            self.equipment.splice(0, self.equipment.length)
            for (let i = 0; i < equipment.length; i++) {
              self.equipment.push({
                id: equipment[i].id_equipment,
                name: equipment[i].nama_equipment + ' (' + equipment[i].nomor_equipment + ')',
                plant_id: equipment[i].plant_id,
                pabrik_id: equipment[i].pabrik_id,
                pabrik: equipment[i].nama_pabrik,
                plant: equipment[i].nama_plant,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })
    },
    chooseEquipment: function (value) {
      this.form.equipment_id = value.id
      this.form.pabrik_id = value.pabrik_id
      // this.form.pabrik = value.pabrik;
      this.form.plant_id = value.plant_id
      // this.form.plant = value.plant;

      // this.ItemPlant = {
      //   id: value.plant_id,
      //   name: value.plant,
      //   pabrik: value.pabrik,
      //   pabrik_id: value.pabrik_id
      // };
    },
    inputChangeSubPart(text) {
      // your search method
      var self = this
      ApiService.get('/report/condition_monitoring/show_sub_part/' + text)
        .then(({ data }) => {
          if (data.status == 'ok') {
            var sub_part = data.data
            self.sub_part.splice(0, self.sub_part.length)

            for (let i = 0; i < sub_part.length; i++) {
              self.sub_part.push({
                id: sub_part[i].id_sub_part,
                name: sub_part[i].nama_sub_part,
              })
            }
          }
        })
        .catch(response => {
          console.log(response)
        })

      this.form.sub_part = text
    },
    itemSelectedSubPart(item) {
      this.form.sub_part = item
    },
    timeout(ms) {
      return new Promise(resolve => {
        window.setTimeout(resolve, ms)
      })
    },
    makeToast(variant = null, $content) {
      this.$bvToast.toast($content, {
        title: `${variant || 'default'}`,
        variant: variant,
        toaster: 'b-toaster-top-center',
        solid: true,
      })
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
    }),
  },
}
</script>
